import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CarsLeft from "../../features/cars/carsLeft";
import CarsRight from "../../features/cars/carsRight";
import client from "../../services";
import Pagination from "../../Components/Pagination";
import { Skeleton } from "@mui/material";
function CustomTabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageCars = () => {
  const [value, setValue] = React.useState(0);
  // const tag = value===0?ServiceStation: Vehicle
  const [page, setPage] = useState<number>(1);
  const [data, setData] = useState<ServiceStation[]>([]);
  const [cars, setCars] = useState<Vehicle[]>([]);
  const [count, setCount] = useState<number>(0);
  const [right, setRight] = useState<ServiceStation>();
  const [left, setLeft] = useState<Vehicle>();
  const [loading, setLoading] = useState<boolean>(false);
  const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
    setValue(newValue);
  };
  const getServiceStation = async () => {
    setLoading(true);
    try {
      const res = await client.get<ServiceApiResponse>(`/serviceStation`, {
        params: { limit: 6, page: page },
      });
      if (res?.status === 200) {
        const { data } = res?.data;
        setData(data?.data);
        setCount(data?.count);
        setRight(data?.data[0]);
        setLoading(false);
      }
    } catch (error: any) {
      console.log(error?.data?.message);
      setLoading(false);
    }
  };
  const getCars = async () => {
    setLoading(true);
    try {
      const res = await client.get<CarApiResponse>(`/vehicle`, {
        params: { limit: 6, page: page },
      });
      if (res?.status === 200) {
        const { data } = res?.data;
        setCars(data?.vehicles);
        setCount(data?.count);
        setLeft(data?.vehicles[0]);
        setLoading(false);
      }
    } catch (error: any) {
      console.log(error?.data?.message);
      setLoading(false);
    }
  };

  const handlePageChange = (event: any, newPage: number) => {
    setPage(newPage + 1);
  };
  const PriceFormatter = (number: number) => {
    const formatter = new Intl.NumberFormat("en-US");
    const formattedNumber = formatter.format(number);
    return formattedNumber;
  };

  const SkeletonCarItem = () => (
    <li className="crsryt_bx">
      <figure>
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="100%"
          height={140}
        />
      </figure>
      <div className="crsryt_cntnt">
        <Skeleton animation="wave" variant="text" width="60%" />
        <Skeleton animation="wave" variant="text" width="40%" />
      </div>
    </li>
  );

  const ServiceStationSkeleton = () => (
    <li className="crs_ryt">
      <figure>
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="100%"
          height={"100%"}
        />
      </figure>
      <Skeleton className="crs_ryt_cntnt" width={"100%"} height={"100%"}>
        <Skeleton animation="wave" variant="text" width="100%" />
        <Skeleton animation="wave" variant="text" width="100%" />
        <Skeleton animation="wave" variant="text" width="100%" />
      </Skeleton>
    </li>
  );

  useEffect(() => {
    value === 0 ?getCars(): getServiceStation() 
  }, [page, value]);

  return (
    <Layout>
      <section>
        <div className="container">
          <div className="crs_mn">
            <div className="left">
              <CustomTabPanel value={value} index={1}>
                <CarsLeft station={right} loading={loading} />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={0}>
                <CarsRight vehicle={left} loading={loading} />
              </CustomTabPanel>
            </div>
            <div className="right">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="tabs"
              >
                <Tab label="Cars" {...a11yProps(0)} />
                <Tab label="Service Stations" {...a11yProps(1)} />
              </Tabs>
              <CustomTabPanel value={value} index={1}>
                <ul className="crs_ryt_mn">
                  {loading
                    ? Array.from(new Array(6)).map((_, index) => (
                        <ServiceStationSkeleton key={index} />
                      ))
                    : data?.length
                    ? data?.map((item, index) => (
                        <li
                          className="crs_ryt"
                          onClick={() => setRight(item)}
                          style={{ cursor: "pointer" }}
                          key={item?._id}
                        >
                          <figure>
                            <img src={item?.image} />
                          </figure>
                          <div className="crs_ryt_cntnt">
                            <h3>{item?.name || "-"}</h3>
                            <h4>
                              {item?.phone ?   (item?.countryCode?.includes("+")
                                ? item?.countryCode
                                : "+" + item?.countryCode || "-") +
                                "-" +
                                (item?.phone || "-"):null}
                            </h4>
                            <h4>{item?.address || ""}</h4>
                          </div>
                        </li>
                      ))
                    : null}
                </ul>
                <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                  <Pagination
                    count={count}
                    page={page}
                    onPageChange={handlePageChange}
                    data={data}
                  />
                </Box>

                {/* <ul className='crs_ryt_mn'>
                                    <li className='crs_ryt'>
                                        <figure><img src='/static/images/location.png'/></figure>
                                        <div className='crs_ryt_cntnt'>
                                            <h3>Maruti Service Station</h3>
                                            <h4>+91 98653274</h4>
                                            <h4>Mohali, Punjab</h4>
                                        </div>
                                    </li>
                                    <li className='crs_ryt'>
                                        <figure><img src='/static/images/location.png'/></figure>
                                        <div className='crs_ryt_cntnt'>
                                            <h3>Maruti Service Station</h3>
                                            <h4>+91 98653274</h4>
                                            <h4>Mohali, Punjab</h4>
                                        </div>
                                    </li>
                                    <li className='crs_ryt'>
                                        <figure><img src='/static/images/location.png'/></figure>
                                        <div className='crs_ryt_cntnt'>
                                            <h3>Maruti Service Station</h3>
                                            <h4>+91 98653274</h4>
                                            <h4>Mohali, Punjab</h4>
                                        </div>
                                    </li>
                                    <li className='crs_ryt'>
                                        <figure><img src='/static/images/location.png'/></figure>
                                        <div className='crs_ryt_cntnt'>
                                            <h3>Maruti Service Station</h3>
                                            <h4>+91 98653274</h4>
                                            <h4>Mohali, Punjab</h4>
                                        </div>
                                    </li>
                                    <li className='crs_ryt'>
                                        <figure><img src='/static/images/location.png'/></figure>
                                        <div className='crs_ryt_cntnt'>
                                            <h3>Maruti Service Station</h3>
                                            <h4>+91 98653274</h4>
                                            <h4>Mohali, Punjab</h4>
                                        </div>
                                    </li>
                                    <li className='crs_ryt'>
                                        <figure><img src='/static/images/location.png'/></figure>
                                        <div className='crs_ryt_cntnt'>
                                            <h3>Maruti Service Station</h3>
                                            <h4>+91 98653274</h4>
                                            <h4>Mohali, Punjab</h4>
                                        </div>
                                    </li>
                                    <li className='crs_ryt'>
                                        <figure><img src='/static/images/location.png'/></figure>
                                        <div className='crs_ryt_cntnt'>
                                            <h3>Maruti Service Station</h3>
                                            <h4>+91 98653274</h4>
                                            <h4>Mohali, Punjab</h4>
                                        </div>
                                    </li>
                                    <li className='crs_ryt'>
                                        <figure><img src='/static/images/location.png'/></figure>
                                        <div className='crs_ryt_cntnt'>
                                            <h3>Maruti Service Station</h3>
                                            <h4>+91 98653274</h4>
                                            <h4>Mohali, Punjab</h4>
                                        </div>
                                    </li>
                                </ul> */}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={0}>
                <ul className="crsryt_img">
                  {loading
                    ? Array.from(new Array(6)).map((_, index) => (
                        <SkeletonCarItem key={index} />
                      ))
                    : cars?.length
                    ? cars?.map((item, index) => (
                        <li
                          className="crsryt_bx active"
                          key={item?._id}
                          style={{ cursor: "pointer" }}
                          onClick={() => setLeft(item)}
                        >
                          <figure>
                            <img src={item?.image[0] || ""} alt="img" />
                          </figure>
                          <div className="crsryt_cntnt">
                            <h3>
                              {item?.name || ""} <br /> ({item?.categoryName || ""})
                            </h3>
                            {
                              item?.price ? 
                              <h4>${PriceFormatter(item?.price || 0)}</h4> : null
                            }
                          </div>
                        </li>
                      ))
                    : null}
                </ul>
                <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                  <Pagination
                    count={count}
                    page={page}
                    onPageChange={handlePageChange}
                    data={cars}
                  />
                </Box>
                {/* <ul className="crsryt_img">
                  <li className="crsryt_bx active">
                    <figure>
                      <img src="/static/images/orng_car.png" alt="img" />
                    </figure>
                    <div className="crsryt_cntnt">
                      <h3>Maruti Alto (Hatchback)</h3>
                      <h4>$12.00</h4>
                    </div>
                  </li>
                  <li className="crsryt_bx">
                    <figure>
                      <img src="/static/images/orng2.png" alt="img" />
                    </figure>
                    <div className="crsryt_cntnt">
                      <h3>Maruti Alto (Hatchback)</h3>
                      <h4>$12.00</h4>
                    </div>
                  </li>
                  <li className="crsryt_bx">
                    <figure>
                      <img src="/static/images/orng2.png" alt="img" />
                    </figure>
                    <div className="crsryt_cntnt">
                      <h3>Maruti Alto (Hatchback)</h3>
                      <h4>$12.00</h4>
                    </div>
                  </li>
                  <li className="crsryt_bx">
                    <figure>
                      <img src="/static/images/gray.png" alt="img" />
                    </figure>
                    <div className="crsryt_cntnt">
                      <h3>Maruti Alto (Hatchback)</h3>
                      <h4>$12.00</h4>
                    </div>
                  </li>
                  <li className="crsryt_bx">
                    <figure>
                      <img src="/static/images/yellow.png" alt="img" />
                    </figure>
                    <div className="crsryt_cntnt">
                      <h3>Maruti Alto (Hatchback)</h3>
                      <h4>$12.00</h4>
                    </div>
                  </li>
                  <li className="crsryt_bx">
                    <figure>
                      <img src="/static/images/yellow.png" alt="img" />
                    </figure>
                    <div className="crsryt_cntnt">
                      <h3>Maruti Alto (Hatchback)</h3>
                      <h4>$12.00</h4>
                    </div>
                  </li>
                </ul> */}
              </CustomTabPanel>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ManageCars;
