import axios from "axios";
import { generateEncryptedKeyBody } from "../utils/crypto";

const API_URL = "https://api.qfiservices.com/api/v1/user";
// live url : http://api.qfiservices.com/api/v1/user,
// dev : https://qfiapi.appgrowthcompany.com/api/v1/user

const header = {
  date: new Date().toISOString(),
  wtoken:true
};

const encryptedHeaders = generateEncryptedKeyBody(header) as CommonBody;


const client = axios.create({
  baseURL: API_URL,
  headers: {
    hash: encryptedHeaders?.hash,
    sek: encryptedHeaders?.sek,
    deviceType: "WEB",
    
  },
});

export default client;
